import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { StyledOrderBlock, StyledBottomBlock } from "./style";
import rightArrow from "../../assets/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsByOrderId, resendMail, uploadVisaDocument } from "../../features/order/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";
import { convertToTitleCase, capitalizeFirstLetter, getPhoneCode } from "../../utils/utility";
import CustomDeclarationDetails from "./CustomDeclarationDetails";
import Declarations from "./Declarations";
import DefenceContent from "./DefenceContent/DefenceContent";
import TransactionModal from "./TransactionModal/TransactionModal";


const ViewOrders = () => {
    const dispatch = useDispatch();
    const { orderId } = useParams();
    const [show, setShow] = useState(false);
    const [showDefencePack, setShowDefencePack] = useState(false);
    const [showDefence, setShowDefence] = useState(false);
    const [showTransaction, setShowTransaction] = useState(false);

    const OrderDetails = useSelector((state) => state?.order?.orderDetails);

    const isLoading = useSelector((state) => state?.order?.loading);
    const [selectedData, setSelectedData] = useState({
        showData: false,
        dataId: 0,
    });
    const [emailId, setEmailId] = useState(OrderDetails?.email);
    const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);
    const [mailServiceProvider, setMailServiceProvider] = useState('AWS');

    const uploadSchema = yup.object({
        email: yup.string().required("Please enter your email"),
        fileUpload: yup.mixed().required("File is required"),
    });

    useEffect(() => {
        if (orderId) {
            dispatch(getOrderDetailsByOrderId(orderId));
        }

        document.title = "Orders Details | Philippines ETD";
    }, [dispatch, orderId]);

    useEffect(() => {
        if (
            (OrderDetails &&
                typeof OrderDetails !== "undefined" &&
                OrderDetails?.email &&
                typeof emailId === "undefined") ||
            orderId !== orderid
        ) {
            setEmailId(OrderDetails?.email);
        }
    }, [OrderDetails, orderid]);

    let defaultInitialValues = {
        orderId: OrderDetails?.order_id,
        email: "",
        fileUpload: "",
    };

    if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
        defaultInitialValues = {
            orderId: OrderDetails?.order_id,
            email: OrderDetails?.email,
            fileUpload: "",
        };
    }

    let [initialValues, setInitialValues] = useState(defaultInitialValues);

    const { values, errors, handleBlur, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
        initialValues: orderId !== null ? defaultInitialValues : initialValues,
        enableReinitialize: true,
        validationSchema: uploadSchema,
        onSubmit: (values) => {
            const data = new FormData();
            data.append("orderId", values.orderId);
            data.append("email", values.email);
            data.append("uploadVisaDoc", values.fileUpload);
            data.append("mailServiceProvider", mailServiceProvider);
            dispatch(uploadVisaDocument(data)).unwrap().then((res) => {
                resetForm();
                if (res.status === 1) {
                    toast.success(`${res.message}`, {
                        className: "toast-message",
                    });
                    dispatch(getOrderDetailsByOrderId(orderId));
                }
            });
        },
    });

    const handleResendMail = (e, serviceProvider) => {
        e.preventDefault();
        setMailServiceProvider(serviceProvider);
        const data = {
            orderId: OrderDetails?.order_id,
            email: emailId,
            mailServiceProvider: serviceProvider
        };
        dispatch(resendMail(data)).unwrap().then((res) => {
            if (res.status === 1) {
                toast.success(`${res.message}`, {
                    className: "toast-message",
                });
                dispatch(getOrderDetailsByOrderId(orderId));
            }
        });
    };

    const customDeclarationOptions = [
        {
            key: 'philippine_currency',
            label: '1. Philippine Currency and/or any Philippine Monetary Instrument in excess of PhP 50,000.00; (i.e. Check, Bank, Draft , etc);'
        },
        {
            key: 'foreign_currency',
            label: '2. Foreign Currency and/or Foreign Monetary Instrument in excess of USD 10,000.00 or its equivalent;'
        },
        {
            key: 'gambling_paraphernalia',
            subKey: 'gambling_paraphernalia_info',
            label: '3. Gambling Paraphernalia'
        },
        {
            key: 'cosmetics_skincare',
            subKey: 'cosmetics_skincare_info',
            label: '4. Cosmetics, skin care products, food supplements and medicines in excess of quantities for personal use;'
        },
        {
            key: 'dangerous_drugs',
            subKey: 'dangerous_drugs_info',
            label: '5. Dangerous drugs such as morphine, marijuana, opium, poppies or synthetic drugs;'
        },
        {
            key: 'firearms_ammunitions',
            subKey: 'firearms_ammunitions_info',
            label: '6. Firearms, ammunitions and explosives;'
        },
        {
            key: 'alcohol_tobacco',
            subKey: 'alcohol_tobacco_info',
            label: '7. Alcohol and/or tobacco products in commercial quantities;'
        },
        {
            key: 'foodstuff_fruit',
            subKey: 'foodstuff_fruit_info',
            label: '8. Foodstuff(s), fruit(s), vegetable(s), live animal(s) (i.e. meat,eggs etc.), marine and aquatic products(s), plant(s) and/or the product(s) and their by-product(s);'
        },
        {
            key: 'mobile_phones',
            subKey: 'mobile_phones_info',
            label: '9. Mobile phones, hand-held radios and similar gadgets in excess of quantities for personal use, and radio commumication equipments;'
        },
        {
            key: 'cremains_human',
            subKey: 'cremains_human_info',
            label: '10.Cremains (human ashes), human organs or tissues;'
        },
        {
            key: 'jewelry_gold',
            subKey: 'jewelry_gold_info',
            label: '11.Jewelry, gold, precious metals or gem'
        },
        {
            key: 'other_goods',
            subKey: 'other_goods_info',
            label: '12. Other goods, not mentioned above;'
        },
    ];

    const declarationFields = {
        gambling_paraphernalia_info: [
            { label: 'Gambling Paraphernalia Description', field: 'gambling_paraphernalia_description' },
            { label: 'Gambling Paraphernalia Quantity', field: 'gambling_paraphernalia_quantity' },
            { label: 'Gambling Paraphernalia Amount in USD', field: 'gambling_paraphernalia_amount_in_usd' },
        ],
        cosmetics_skincare_info: [
            { label: 'Cosmetics Skincare Description', field: 'cosmetics_skincare_description' },
            { label: 'Cosmetics Skincare Quantity', field: 'cosmetics_skincare_quantity' },
            { label: 'Cosmetics Skincare Amount in USD', field: 'cosmetics_skincare_amount_in_usd' },
        ],
        dangerous_drugs_info: [
            { label: 'Dangerous Drugs Description', field: 'dangerous_drugs_description' },
            { label: 'Dangerous Drugs Quantity', field: 'dangerous_drugs_quantity' },
            { label: 'Dangerous Drugs Amount in USD', field: 'dangerous_drugs_amount_in_usd' },
        ],
        firearms_ammunitions_info: [
            { label: 'Firearms Ammunitions Description', field: 'firearms_ammunitions_description' },
            { label: 'Firearms Ammunitions Quantity', field: 'firearms_ammunitions_quantity' },
            { label: 'Firearms Ammunitions Amount in USD', field: 'firearms_ammunitions_amount_in_usd' },
        ],
        alcohol_tobacco_info: [
            { label: 'Alcohol Tobacco Description', field: 'alcohol_tobacco_description' },
            { label: 'Alcohol Tobacco Quantity', field: 'alcohol_tobacco_quantity' },
            { label: 'Alcohol Tobacco Amount in USD', field: 'alcohol_tobacco_amount_in_usd' },
        ],
        foodstuff_fruit_info: [
            { label: 'Foodstuff Fruit Description', field: 'foodstuff_fruit_description' },
            { label: 'Foodstuff Fruit Quantity', field: 'foodstuff_fruit_quantity' },
            { label: 'Foodstuff Fruit Amount in USD', field: 'foodstuff_fruit_amount_in_usd' },
        ],
        mobile_phones_info: [
            { label: 'Mobile Phones Description', field: 'mobile_phones_description' },
            { label: 'Mobile Phones Quantity', field: 'mobile_phones_quantity' },
            { label: 'Mobile Phones Amount in USD', field: 'mobile_phones_amount_in_usd' },
        ],
        cremains_human_info: [
            { label: 'Cremains Human Description', field: 'cremains_human_description' },
            { label: 'Cremains Human Quantity', field: 'cremains_human_quantity' },
            { label: 'Cremains Human Amount in USD', field: 'cremains_human_amount_in_usd' },
        ],
        jewelry_gold_info: [
            { label: 'Jewelry Gold Description', field: 'jewelry_gold_description' },
            { label: 'Jewelry Gold Quantity', field: 'jewelry_gold_quantity' },
            { label: 'Jewelry Gold Amount in USD', field: 'jewelry_gold_amount_in_usd' },
        ],
        other_goods_info: [
            { label: 'Other Goods Description', field: 'other_goods_description' },
            { label: 'Other Goods Quantity', field: 'other_goods_quantity' },
            { label: 'Other Goods Amount in USD', field: 'other_goods_amount_in_usd' },
        ]
    }

    const dereferenceField = (subKey) => {
        return declarationFields[subKey] || [];
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    {OrderDetails?.mailHistory?.length !== 0 && (
                        <StyledOrderBlock>
                            <h2>Email History</h2>
                            <div className="table-block table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "5%" }}>#</th>
                                            <th style={{ width: "10%" }}>Order ID</th>
                                            <th style={{ width: "30%" }}>Download</th>
                                            <th style={{ width: "25%" }}>Date & Time</th>
                                            <th style={{ width: "30%" }}>Mail Provider</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {OrderDetails?.mailHistory?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td className="font-normal">{item?.order_id}</td>
                                                    {OrderDetails?.uploadDoc?.length ? (
                                                        <td>
                                                            <a
                                                                href={
                                                                    OrderDetails?.length !== 0
                                                                        ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH
                                                                        }docs/${OrderDetails?.order_id}_${item?.uploadDoc == null
                                                                            ? OrderDetails?.uploadDoc[0]?.file1
                                                                            : item?.uploadDoc.file1
                                                                        }`
                                                                        : null
                                                                }
                                                                className="blue-btn"
                                                                download
                                                                rel="noreferrer"
                                                            >
                                                                Download File{" "}
                                                            </a>
                                                        </td>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <td>
                                                        {moment(item?.create_ts).utc().format(
                                                            "MM/DD/YYYY h:mm:ss"
                                                        )}
                                                    </td>
                                                    <td>{item?.uploadDoc?.mail_service_provider}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <form className="form-inline">
                                    <div className="form-group">
                                        <label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="form-control input-class"
                                                value={emailId}
                                                onChange={(e) => setEmailId(e.target.value)}
                                            />
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <button
                                            type="button"
                                            className="blue-btn"
                                            id="download_btn"
                                            disabled={isLoading}
                                            onClick={(e) => {
                                                handleResendMail(e, "AWS");
                                            }}
                                        >
                                            Resend Mail(AWS){" "}
                                            <span>
                                                <img src={rightArrow} alt="rightArrow" />
                                            </span>
                                        </button>

                                        <button
                                            type="button"
                                            className="blue-btn"
                                            id="download_btn_sendgrid"
                                            disabled={isLoading}
                                            onClick={(e) => {
                                                handleResendMail(e, "Sendgrid");
                                            }}
                                        >
                                            Resend Mail(Sendgrid){" "}
                                            <span>
                                                <img src={rightArrow} alt="rightArrow" />
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </StyledOrderBlock>
                    )}
                </div>
                <div className="col-md-12">
                    <StyledOrderBlock>
                        <h2>Upload Document</h2>
                        <div className="table-block table-responsive fw-mobileview">
                            <form onSubmit={handleSubmit}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "15%" }}>Order ID</td>
                                            <td style={{ width: "35%" }} className="font-normal">
                                                <input
                                                    type="text"
                                                    name="orderId"
                                                    className="form-control"
                                                    placeholder="Order ID"
                                                    disabled
                                                    value={values?.orderId}
                                                />
                                            </td>
                                            <td style={{ width: "15%" }}>Email ID</td>
                                            <td style={{ width: "35%" }}>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    value={values?.email}
                                                    onChange={handleChange}
                                                    placeholder="Email"
                                                />
                                                <p>{errors.email}</p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>PDF</td>
                                            <tr>
                                                <td>
                                                    <label className="file-upload">
                                                        <input
                                                            type="file"
                                                            className=""
                                                            id="fileUpload"
                                                            name="fileUpload"
                                                            accept=".pdf,image/*"
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "fileUpload",
                                                                    e.currentTarget.files[0]
                                                                )
                                                            }
                                                            onBlur={handleBlur}
                                                        />
                                                        <span>Choose File</span>
                                                    </label>
                                                    <span className="slcted-file"></span>
                                                    <p>{errors.fileUpload}</p>
                                                </td>
                                            </tr>
                                        </tr>
                                        {OrderDetails?.uploadDoc?.length ? (
                                            <td colSpan="2">
                                                <a
                                                    href={
                                                        OrderDetails?.length !== 0
                                                            ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                                                            : null
                                                    }
                                                    className="blue-btn"
                                                    download
                                                    rel="noreferrer"
                                                >
                                                    Download File{" "}
                                                </a>
                                            </td>
                                        ) : (
                                            ""
                                        )}
                                    </tbody>
                                </table>
                                <br />
                                <button
                                    type="submit"
                                    className="blue-btn"
                                    id="submit_btn"
                                    disabled={isLoading}
                                    onClick={() => setMailServiceProvider('AWS')}
                                >
                                    Submit (AWS){" "}
                                    <span>
                                        <img src={rightArrow} alt="rightArrow" />
                                    </span>
                                </button>
                                <button
                                    type="submit"
                                    className="blue-btn"
                                    id="submit_btn_sendgrid"
                                    disabled={isLoading}
                                    onClick={() => setMailServiceProvider('Sendgrid')}
                                >
                                    Submit (SendGrid){" "}
                                    <span>
                                        <img src={rightArrow} alt="rightArrow" />
                                    </span>
                                </button>
                            </form>
                        </div>
                    </StyledOrderBlock>
                </div>
                <div className="col-md-12">

                    <StyledOrderBlock>
                        <h2>Order Details</h2>
                        <div className="table-block table-responsive">
                            <table>
                                <tbody>
                                    {OrderDetails?.order_id && (
                                        <tr>
                                            <td>Order ID:</td>
                                            <td>{OrderDetails?.order_id}</td>
                                        </tr>
                                    )}

                                    {typeof OrderDetails?.email != "undefined" && (
                                        <tr>
                                            <td>Email Address</td>
                                            <td>{OrderDetails?.email}</td>
                                        </tr>
                                    )}

                                    {typeof OrderDetails?.processing_mode != "undefined" && (
                                        <tr>
                                            <td>Processing Mode</td>
                                            <td>{OrderDetails?.processing_mode == "Regular Processing" ? "Standard Processing" : OrderDetails?.processing_mode}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.signature_name && (
                                        <tr>
                                            <td>Signature Name</td>
                                            <td>{OrderDetails?.signature_name}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </StyledOrderBlock>

                    <StyledOrderBlock>
                        <h2>Personal Information</h2>
                        <div className="table-block table-responsive">
                            <table>
                                <tbody>
                                    {OrderDetails?.applicant_photo && (
                                        <tr>
                                            <td>Upload profile photo</td>
                                            <td>
                                                <a
                                                    href={`https://philippines-etd.com/uploads/${OrderDetails?.applicant_photo}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Click here
                                                </a>
                                            </td>
                                        </tr>
                                    )}
                                    {OrderDetails?.passport_type && (
                                        <tr>
                                            <td>Passport Type</td>
                                            <td>{convertToTitleCase(OrderDetails?.passport_type)}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.overseas_filipino_worker !== undefined && (
                                        <tr>
                                            <td>Are you an Overseas Filipino Worker (OFW)</td>
                                            <td>{OrderDetails?.overseas_filipino_worker === 0 ? "No" : "Yes"}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.ofw_classification !== undefined && OrderDetails?.ofw_classification != null && (
                                        <tr>
                                            <td>OFW Classification</td>
                                            <td>{OrderDetails?.ofw_classification}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.first_name && (
                                        <tr>
                                            <td>First Name</td>
                                            <td>{OrderDetails?.first_name}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.middle_name && (
                                        <tr>
                                            <td>Middle Name (optional)</td>
                                            <td>{OrderDetails?.middle_name}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.last_name && (
                                        <tr>
                                            <td>Last Name</td>
                                            <td>{OrderDetails?.last_name}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.suffix && (
                                        <tr>
                                            <td>Suffix</td>
                                            <td>{OrderDetails?.suffix}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.sex && (
                                        <tr>
                                            <td>Sex</td>
                                            <td>{OrderDetails?.sex}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.date_of_birth && (
                                        <tr>
                                            <td>Birth Date (MM/DD/YYYY)</td>
                                            <td>{OrderDetails?.date_of_birth ? moment(OrderDetails?.date_of_birth).utc().format('MM/DD/YYYY') : ""}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.phone_number && OrderDetails?.country_code && (
                                        <tr>
                                            <td>Mobile Number</td>
                                            <td>{`+${OrderDetails?.country_code} ${OrderDetails?.phone_number}`}</td>
                                        </tr>
                                    )}
                                    {OrderDetails?.phone_number && OrderDetails?.country_phone_code && (
                                        <tr>
                                            <td>Mobile Number</td>
                                            <td>{`+ ${getPhoneCode(OrderDetails?.country_phone_code)} ${OrderDetails?.phone_number}`}</td>
                                        </tr>
                                    )}
                                    {OrderDetails?.citizenship_id && (
                                        <tr>
                                            <td>Citizenship Nationality</td>
                                            <td>{capitalizeFirstLetter(OrderDetails?.citizenship_id)}</td>
                                        </tr>
                                    )}
                                    {OrderDetails?.citizenship && (
                                        <tr>
                                            <td>Citizenship</td>
                                            <td>{OrderDetails?.citizenship}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.country_of_birth && (
                                        <tr>
                                            <td>Country of Birth</td>
                                            <td>{OrderDetails?.country_of_birth}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.passport_number && (
                                        <tr>
                                            <td>Passport Number</td>
                                            <td>{OrderDetails?.passport_number}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.passport_issued_country && (
                                        <tr>
                                            <td>Passport Issued Country</td>
                                            <td>{OrderDetails?.passport_issued_country}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.date_of_issued && (
                                        <tr>
                                            <td>Passport Issued Date (MM/DD/YYYY)</td>
                                            <td>{OrderDetails?.date_of_issued ? moment(OrderDetails?.date_of_issued).utc().format('MM/DD/YYYY') : ""}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.occupation && (
                                        <tr>
                                            <td>Occupation</td>
                                            <td>{OrderDetails?.occupation}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </StyledOrderBlock>

                    <StyledOrderBlock>
                        <h2>Address Information</h2>
                        <div className="table-block table-responsive">
                            <table>
                                <tbody>
                                    {OrderDetails?.permanent_country_of_residence && (
                                        <tr>
                                            <td>Permanent Country Of Residence</td>
                                            <td>{OrderDetails?.permanent_country_of_residence}</td>
                                        </tr>
                                    )}
                                    {OrderDetails?.permanent_state_details && (
                                        <tr>
                                            <td>State/ Province </td>
                                            <td>{OrderDetails?.permanent_state_details}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.permanent_municipality_details && (
                                        <tr>
                                            <td>Municipality</td>
                                            <td>{OrderDetails?.permanent_municipality_details}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.permanent_barangay && (
                                        <tr>
                                            <td>Barangay</td>
                                            <td>{OrderDetails?.permanent_barangay}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.address && (
                                        <tr>
                                            <td>H.no/Building/city/state/province</td>
                                            <td>{OrderDetails?.address}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.address_line_2 && (
                                        <tr>
                                            <td>Address line 2(optional)</td>
                                            <td>{OrderDetails?.address_line_2}</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </StyledOrderBlock>

                    <StyledOrderBlock>
                        <h2>Travel Details</h2>
                        <div className="table-block table-responsive">
                            <table>
                                <tbody>
                                    {OrderDetails?.travel_type && (
                                        <tr>
                                            <td>Travel Type</td>
                                            <td>{capitalizeFirstLetter(OrderDetails?.travel_type)}</td>
                                        </tr>
                                    )}
                                    {OrderDetails?.transportation_type && (
                                        <tr>
                                            <td>Transportation Type</td>
                                            <td>{capitalizeFirstLetter(OrderDetails?.transportation_type)}</td>
                                        </tr>
                                    )}
                                    {typeof OrderDetails?.email != "undefined" && (
                                        <tr>
                                            <td>Email Address</td>
                                            <td>{OrderDetails?.email}</td>
                                        </tr>
                                    )}
                                    {typeof OrderDetails?.confirm_email != "undefined" && (
                                        <tr>
                                            <td>Re-Enter Email</td>
                                            <td>{OrderDetails?.confirm_email}</td>
                                        </tr>
                                    )}
                                    {OrderDetails?.citizenshipCountry && (
                                        <tr>
                                            <td>Citizenship</td>
                                            <td>{OrderDetails?.citizenshipCountry}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </StyledOrderBlock>

                    <StyledOrderBlock>
                        <h2>Travel Information</h2>
                        <div className="table-block table-responsive">
                            <table>
                                <tbody>
                                    {OrderDetails?.purpose_of_visit && (
                                        <tr>
                                            <td>Purpose of visit</td>
                                            <td>{capitalizeFirstLetter(OrderDetails?.purpose_of_visit)}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.traveller_type && (
                                        <tr>
                                            <td>Traveller Type</td>
                                            <td>{convertToTitleCase(OrderDetails?.traveller_type)}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.name_of_airline && (
                                        <tr>
                                            <td>Name of the airline</td>
                                            <td>{OrderDetails?.name_of_airline}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.flight_number && (
                                        <tr>
                                            <td>Flight Number</td>
                                            <td>{OrderDetails?.flight_number}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.special_flight_number && (
                                        <tr>
                                            <td>Special Flight Number</td>
                                            <td>{OrderDetails?.special_flight_number}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.country_of_origin && (
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>{OrderDetails?.country_of_origin}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.airport_of_origin && (
                                        <tr>
                                            <td>Airport Of Origin</td>
                                            <td>{OrderDetails?.airport_of_origin}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.date_of_departure && (
                                        <tr>
                                            <td>Date of Departure</td>
                                            <td>{OrderDetails?.date_of_departure ? moment(OrderDetails?.date_of_departure).utc().format('MM/DD/YYYY') : ""}</td>
                                        </tr>
                                    )}
                                    {OrderDetails?.date_of_return && (
                                        <tr>
                                            <td>Date of Return</td>
                                            <td>{OrderDetails?.date_of_return ? moment(OrderDetails?.date_of_return).utc().format('MM/DD/YYYY') : ""}</td>
                                        </tr>
                                    )}
                                    {OrderDetails?.country_of_transit && (
                                        <tr>
                                            <td>Country of Transit</td>
                                            <td>{OrderDetails?.country_of_transit}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.transportation_type == "air" && OrderDetails?.airport_of_transit && (
                                        <tr>
                                            <td>Airport of Transit</td>
                                            <td>{OrderDetails?.airport_of_transit}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.transportation_type == "sea" && OrderDetails?.seaport_of_transit && (
                                        <tr>
                                            <td>Seaport of Transit</td>
                                            <td>{OrderDetails?.seaport_of_transit}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.date_of_transit && (
                                        <tr>
                                            <td>Date of Transit</td>
                                            <td>{OrderDetails?.date_of_transit ? moment(OrderDetails?.date_of_transit).utc().format('MM/DD/YYYY') : ""}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.airport_of_arrival_departure && (
                                        <tr>
                                            <td>Airport of Arrival</td>
                                            <td>{OrderDetails?.airport_of_arrival_departure}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.date_of_travel && (
                                        <tr>
                                            <td>Date of Arrival</td>
                                            <td>{OrderDetails?.date_of_travel ? moment(OrderDetails?.date_of_travel).utc().format('MM/DD/YYYY') : ""}</td>
                                        </tr>
                                    )}



                                    {OrderDetails?.seat_number && (
                                        <tr>
                                            <td>Seat / Bed number</td>
                                            <td>{OrderDetails?.seat_number}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </StyledOrderBlock>

                    <StyledOrderBlock>
                        <h2>Destination</h2>
                        <div className="table-block table-responsive">
                            <table>
                                <tbody>
                                    {OrderDetails?.destination_type && (
                                        <tr>
                                            <td>Destination Type</td>
                                            <td>{capitalizeFirstLetter(OrderDetails?.destination_type)}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.hotel_details && (
                                        <tr>
                                            <td>Hotel/Resort</td>
                                            <td>{OrderDetails?.hotel_details}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.destination_type === "hotel" && OrderDetails?.hotel_name && (
                                        <tr>
                                            <td>Hotel/Resort</td>
                                            <td>{OrderDetails?.hotel_name}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.destination_type === "hotel" && OrderDetails?.hotel_details === "other" && (
                                        <tr>
                                            <td>Hotel Name(other)</td>
                                            <td>{OrderDetails?.hotel_other}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.contact_information && (
                                        <tr>
                                            <td>Residence Address</td>
                                            <td>{OrderDetails?.contact_information}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.state_details && (
                                        <tr>
                                            <td>State/ Province</td>
                                            <td>{OrderDetails?.state_details}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.municipality_details && (
                                        <tr>
                                            <td>Municipality</td>
                                            <td>{OrderDetails?.municipality_details}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.house_no && (
                                        <tr>
                                            <td>H.no/Building/street/airbnb</td>
                                            <td>{OrderDetails?.house_no}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.airport_details && (
                                        <tr>
                                            <td>Airport</td>
                                            <td>{OrderDetails?.airport_details}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.country_of_destination && (
                                        <tr>
                                            <td>Country of Destination</td>
                                            <td>{OrderDetails?.country_of_destination}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.airport_of_destination && (
                                        <tr>
                                            <td>Airport of Destination</td>
                                            <td>{OrderDetails?.airport_of_destination}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.destination_address && (
                                        <tr>
                                            <td>Destination Address</td>
                                            <td>{OrderDetails?.destination_address}</td>
                                        </tr>
                                    )}
                                    
                                </tbody>
                            </table>
                        </div>
                    </StyledOrderBlock>

                    <StyledOrderBlock>
                        <h2>Health Declaration</h2>
                        <div className="table-block table-responsive">
                            <table>
                                <tbody>
                                    {OrderDetails?.country_visited && (
                                        <tr>
                                            <td>Country(ies) worked, visited and transited in last 30 days</td>
                                            <td>{OrderDetails?.country_visited == "yes" ? "Yes" : "No"}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.infectious_disease !== undefined && (
                                        <tr>
                                            <td>Have you had any history of exposure to a person who is sick or known to have communicable/infectious disease in the past 30 days prior to travel?*</td>
                                            <td>{OrderDetails?.infectious_disease == "yes" ? "Yes" : "No"}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.sick_in_past_thirty_days !== undefined && (
                                        <tr>
                                            <td>Have you been sick in the past 30 days?</td>
                                            <td>{OrderDetails?.sick_in_past_thirty_days == "yes" ? "Yes" : "No"}</td>
                                        </tr>
                                    )}

                                    {OrderDetails?.symtoms && (
                                        <tr>
                                            <td>Symptoms</td>
                                            <td>{OrderDetails?.symtoms}</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </StyledOrderBlock>
                    {
                        (typeof OrderDetails?.travel_type !== "undefined" &&
                            OrderDetails?.travel_type == "arrival") &&
                        (typeof OrderDetails?.transportation_type !== "undefined" &&
                            OrderDetails?.transportation_type == "air") && (
                            <StyledOrderBlock>
                                <h2>Other Travel Details</h2>
                                <div className="table-block table-responsive">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Accompanied Family Members</td>
                                                <td>
                                                    <b>Below 18 yrs. old:</b> {OrderDetails?.family_members_below_18 || '0'} <br />
                                                    <b>18 yrs. old and above: </b> {OrderDetails?.family_members_18_and_above || '0'} <br />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>No. of Baggage</td>
                                                <td>
                                                    <b>Checked-in (pcs):</b> {OrderDetails?.baggage_checked_in || '0'} <br />
                                                    <b>Hand-carried (pcs):</b> {OrderDetails?.baggage_hand_carried || '0'} <br />
                                                </td>
                                            </tr>
                                            {OrderDetails?.first_time_visiting_philippines !== undefined && (
                                                <tr>
                                                    <td>First time visiting Philippines?</td>
                                                    <td>{OrderDetails?.first_time_visiting_philippines == "yes" ? "Yes" : "No"}</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </StyledOrderBlock>
                        )
                    }
                    {typeof OrderDetails?.transportation_type !== "undefined" && OrderDetails?.transportation_type != "sea" && (
                        <StyledOrderBlock>
                            <h2>Customs - General Declaration</h2>
                            <div className="table-block table-responsive">
                                <table>
                                    <tbody>
                                        {typeof OrderDetails?.transportation_type !== "undefined" && OrderDetails?.travel_type !== "departure" && (
                                            <>
                                                {OrderDetails?.total_amount_of_goods && (
                                                    <tr>
                                                        <td>Total Amount of goods purchased and/or acquired abroad?</td>
                                                        <td>{OrderDetails?.total_amount_of_goods}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.amount_declaration && (
                                                    <tr>
                                                        <td>Amount</td>
                                                        <td>{OrderDetails?.amount_declaration}</td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                        {
                                            typeof OrderDetails?.transportation_type !== "undefined" && OrderDetails?.travel_type !== "departure" ? (
                                                Array.isArray(customDeclarationOptions) && customDeclarationOptions.map((declaration) => (
                                                    <React.Fragment key={declaration.key}>
                                                        <tr>
                                                            <td>{declaration.label}</td>
                                                            <td>{OrderDetails?.[declaration.key] === "yes" ? "Yes" : "No"}</td>
                                                        </tr>
                                                        {OrderDetails?.[declaration.key] === "yes" && Array.isArray(OrderDetails.custom_declaration_details) &&
                                                            OrderDetails.custom_declaration_details.map((customDeclaration, index) => (
                                                                customDeclaration.type === declaration.subKey ? (
                                                                    <React.Fragment key={`${declaration.key}-${index}`}>
                                                                        {customDeclaration.details.map((item, itemIndex) => (
                                                                            <React.Fragment key={itemIndex}>
                                                                                <tr>
                                                                                    <td>Declaration ({itemIndex + 1})</td>
                                                                                    <td>{item[dereferenceField(declaration.subKey)[0].field]}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Quantity ({itemIndex + 1})</td>
                                                                                    <td>{item[dereferenceField(declaration.subKey)[1].field]}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Amount ({itemIndex + 1})</td>
                                                                                    <td>{item[dereferenceField(declaration.subKey)[2].field]}</td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                ) : null
                                                            ))
                                                        }
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <>
                                                    {OrderDetails?.philippine_currency && (
                                                        <tr>
                                                            <td>1. Philippine Currency and/or any Philippine Monetary Instrument in excess of PhP 50,000.00; (i.e. Check, Bank, Draft , etc);</td>
                                                            <td>{OrderDetails?.philippine_currency}</td>
                                                        </tr>
                                                    )}
                                                    {OrderDetails?.foreign_currency && (
                                                        <tr>
                                                            <td>2. Foreign Currency and/or Foreign Monetary Instrument in excess of USD 10,000.00 or its equivalent;</td>
                                                            <td>{OrderDetails?.foreign_currency}</td>
                                                        </tr>
                                                    )}
                                                </>
                                            )
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </StyledOrderBlock>
                    )}
                    {
                        typeof OrderDetails?.philippine_currency !== "undefined" &&
                        typeof OrderDetails?.foreign_currency !== "undefined" &&
                        typeof OrderDetails?.instrument_information !== "undefined" &&
                        OrderDetails?.instrument_information.length > 0 &&
                        (OrderDetails?.philippine_currency == "yes" || OrderDetails?.foreign_currency == "yes") && (
                            <>
                                <StyledOrderBlock>
                                    <h2>For Customs - Currency Declaration</h2>
                                    <h2>Owner Of Currencies Or Monetary Instruments</h2>
                                    <div className="table-block table-responsive">
                                        <table>
                                            <tbody>
                                                {OrderDetails?.owner_currency_declaration_business_name && (
                                                    <tr>
                                                        <td>Business Name</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_business_name}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_first_name && (
                                                    <tr>
                                                        <td>First Name</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_first_name}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_middle_name && (
                                                    <tr>
                                                        <td>Middle Name</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_middle_name}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_last_name && (
                                                    <tr>
                                                        <td>Last Name</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_last_name}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_suffix && (
                                                    <tr>
                                                        <td>Suffix</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_suffix}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_occupation && (
                                                    <tr>
                                                        <td>Occupation</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_occupation}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_country && (
                                                    <tr>
                                                        <td>Country</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_country}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_state_details && (
                                                    <tr>
                                                        <td>State/Province</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_state_details}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_municipality_details && (
                                                    <tr>
                                                        <td>Municipality</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_municipality_details}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_barangay && (
                                                    <tr>
                                                        <td>Barangay</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_barangay}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_street && (
                                                    <tr>
                                                        <td>H.no/street</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_street}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.owner_currency_declaration_pincode && (
                                                    <tr>
                                                        <td>Postcode</td>
                                                        <td>{OrderDetails?.owner_currency_declaration_pincode}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </StyledOrderBlock>
                                <StyledOrderBlock>
                                    <h2>Recipient Of Currencies Or Monetary Instruments</h2>
                                    <div className="table-block table-responsive">
                                        <table>
                                            <tbody>
                                                {OrderDetails?.recipient_currency_declaration_business_name && (
                                                    <tr>
                                                        <td>Business Name</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_business_name}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_first_name && (
                                                    <tr>
                                                        <td>First Name</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_first_name}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_middle_name && (
                                                    <tr>
                                                        <td>Middle Name</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_middle_name}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_last_name && (
                                                    <tr>
                                                        <td>Last Name</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_last_name}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_suffix && (
                                                    <tr>
                                                        <td>Suffix</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_suffix}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_occupation && (
                                                    <tr>
                                                        <td>Occupation</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_occupation}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_country && (
                                                    <tr>
                                                        <td>Country</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_country}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_state_details && (
                                                    <tr>
                                                        <td>State/Province</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_state_details}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_municipality_details && (
                                                    <tr>
                                                        <td>Municipality</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_municipality_details}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_barangay && (
                                                    <tr>
                                                        <td>Barangay</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_barangay}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_street && (
                                                    <tr>
                                                        <td>H.no/street</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_street}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.recipient_currency_declaration_pincode && (
                                                    <tr>
                                                        <td>Postcode</td>
                                                        <td>{OrderDetails?.recipient_currency_declaration_pincode}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </StyledOrderBlock>
                                <StyledOrderBlock>
                                    <h2>Currency Or Monetary Instrument Information</h2>
                                    <div className="table-block table-responsive">
                                        <table>
                                            <tbody>
                                                {typeof OrderDetails?.instrument_information !== "undefined" &&
                                                    OrderDetails?.instrument_information.length > 0 &&
                                                    OrderDetails?.instrument_information.map((instrumentInformation, index) => (
                                                        <React.Fragment key={instrumentInformation.id}>
                                                            <tr>
                                                                <td>Currency ({index + 1})</td>
                                                                <td>{instrumentInformation?.currency}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monetary Instrument ({index + 1})</td>
                                                                <td>{instrumentInformation?.monetaryInstrument}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Amount ({index + 1})</td>
                                                                <td>{instrumentInformation?.amount}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))
                                                }
                                                {OrderDetails?.recipient_currency_declaration_business_name && (
                                                    <tr>
                                                        <td>Date of BSP authorization if transferring Philippine Pesos in excess of PHP50,000(MM/DD/YYYY)</td>
                                                        <td>{OrderDetails?.date_of_bsp ? moment(OrderDetails?.date_of_bsp).utc().format('MM/DD/YYYY') : ""}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.sources_of_currencies_salary && (
                                                    <tr>
                                                        <td>Sources of currencies or monetary instruments</td>
                                                        <td>{OrderDetails?.sources_of_currencies_salary}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.purpose_of_transport_leisure && (
                                                    <tr>
                                                        <td>Purpose's of the Transport of Foreign Currencies or Other Foreign Currency-Denominated Bearer Monetary Instruments</td>
                                                        <td>{OrderDetails?.purpose_of_transport_leisure}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </StyledOrderBlock>
                                <StyledOrderBlock>
                                    <h2>Required Information By The Boc And Amlc - Other Travel Details</h2>
                                    <div className="table-block table-responsive">
                                        <table>
                                            <tbody>
                                                {OrderDetails?.physically_transferred && OrderDetails?.physically_transferred == "Yes" ? (
                                                    <tr>
                                                        <td>If physically transferred by a person</td>
                                                        <td>Yes</td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td>If shipped through courrier services</td>
                                                        <td>Yes</td>
                                                    </tr>
                                                )
                                                }
                                                {OrderDetails?.no_of_days_philippines && (
                                                    <tr>
                                                        <td>No. of days in the Philippines</td>
                                                        <td>{OrderDetails?.no_of_days_philippines}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.date_of_last_travel_philippines && (
                                                    <tr>
                                                        <td>Last travel to the Philippines</td>
                                                        <td>{OrderDetails?.date_of_last_travel_philippines ? moment(OrderDetails?.date_of_last_travel_philippines).utc().format('MM/DD/YYYY') : ""}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.name_of_courrier && (
                                                    <tr>
                                                        <td>Name of Courrier/ Courrier Company</td>
                                                        <td>{OrderDetails?.name_of_courrier}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.bill_of_landing && (
                                                    <tr>
                                                        <td>Bill of landing/Airway Bill No.</td>
                                                        <td>{OrderDetails?.bill_of_landing}</td>
                                                    </tr>
                                                )}
                                                {OrderDetails?.date_of_bill_of_landing && (
                                                    <tr>
                                                        <td>Bill of landing/Airway Bill Date</td>
                                                        <td>{OrderDetails?.date_of_bill_of_landing ? moment(OrderDetails?.date_of_bill_of_landing).utc().format('MM/DD/YYYY') : ""}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </StyledOrderBlock>
                            </>
                        )
                    }
                    <StyledOrderBlock>
                        <h2>Download History</h2>
                        <div className="table-block table-responsive">
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th width="5%">#</th>
                                        <th width="10%">Order ID</th>
                                        <th width="22%">Date & Time</th>
                                        <th width="23%">Downloaded File</th>
                                        <th width="20%">IP Address</th>
                                        <th width="20%">Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {OrderDetails?.downloadHistory ? (
                                        OrderDetails?.downloadHistory?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.order_id}</td>
                                                    <td>
                                                        {moment(item?.create_ts).utc().format(
                                                            "MM/DD/YYYY hh:mm:ss"
                                                        )}{" "}
                                                        EST
                                                    </td>
                                                    <td>
                                                        {OrderDetails?.uploadDoc?.length !== 0 && (
                                                            <a
                                                                href={
                                                                    OrderDetails?.uploadDoc !== undefined
                                                                        ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                                                        : null
                                                                }
                                                                className="blue-btn"
                                                                target="_blank"
                                                                // download
                                                                rel="noreferrer"
                                                            >
                                                                Download File
                                                            </a>
                                                        )}
                                                    </td>
                                                    <td>{item?.ip}</td>
                                                    <td>{item?.timezone}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="4" align="center">
                                                No record found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <form className="form-inline">
                                <div className="form-group">
                                    <label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="form-control input-class"
                                            value={emailId}
                                            onChange={(e) => setEmailId(e.target.value)}
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="blue-btn"
                                        id="download_btn"
                                        onClick={handleResendMail}
                                        disabled={isLoading}
                                    >
                                        Sent Mail{" "}
                                        <span>
                                            <img src={rightArrow} alt="rightArrow" />
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </StyledOrderBlock>
                    <AddComment />
                    <StyledOrderBlock>
                        <h2>Comment History</h2>
                        <div className="table-block table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th width="10%">#</th>
                                        <th width="20%">Order ID</th>
                                        <th width="20%">Date & Time</th>
                                        <th width="15%">Team</th>
                                        <th width="35%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {typeof OrderDetails !== "undefined" &&
                                        OrderDetails.remark ? (
                                        OrderDetails.remark?.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item?.order_id}</td>
                                                    <td>
                                                        {moment(item?.us_date).format("MM/DD/YYYY")}
                                                        {"  "}
                                                        {item?.us_time}
                                                    </td>
                                                    <td>{item?.remark_name}</td>
                                                    <td>
                                                        {selectedData.showData &&
                                                            selectedData.dataId === item.id ? (
                                                            <div
                                                                rel="40"
                                                                className="btn blue-btn"
                                                                onClick={() =>
                                                                    setSelectedData({
                                                                        showData: !selectedData.showData,
                                                                        dataId: item?.id,
                                                                    })
                                                                }
                                                            >
                                                                {" "}
                                                                Hide
                                                            </div>
                                                        ) : (
                                                            <div
                                                                rel="40"
                                                                onClick={() =>
                                                                    setSelectedData({
                                                                        showData: !selectedData.showData,
                                                                        dataId: item?.id,
                                                                    })
                                                                }
                                                                className="btn blue-btn"
                                                            >
                                                                {" "}
                                                                Show
                                                            </div>
                                                        )}
                                                        <br />
                                                        {selectedData.showData &&
                                                            selectedData.dataId === item.id && (
                                                                <div id="40">
                                                                    <textarea
                                                                        name="remark"
                                                                        className="form-control"
                                                                        defaultValue={item?.remark}
                                                                    ></textarea>
                                                                    <b>
                                                                        Whatsapp:
                                                                        {item?.whatsapp ? item?.whatsapp : "N"}
                                                                    </b>
                                                                    <b>
                                                                        Telephone:
                                                                        {item?.telephone ? item?.telephone : "N"}
                                                                    </b>
                                                                    <b>
                                                                        Refund:
                                                                        {item?.status_refund
                                                                            ? item?.status_refund
                                                                            : "N"}
                                                                    </b>
                                                                    <b>
                                                                        Voided:
                                                                        {item?.voided ? item?.voided : "N"}
                                                                    </b>
                                                                    <b>
                                                                        Chargeback:
                                                                        {item?.chargeback ? item?.chargeback : "N"}
                                                                    </b>
                                                                    <b>
                                                                        ReportError:
                                                                        {item?.report_error
                                                                            ? item?.report_error
                                                                            : "N"}
                                                                    </b>
                                                                </div>
                                                            )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="5" align="center">
                                                No record found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </StyledOrderBlock>
                </div>

                <StyledBottomBlock>
                    <button
                        type="button"
                        className="blue-btn"
                        data-toggle="modal"
                        data-target="#refundModal"
                        onClick={() => setShow(true)}
                    >
                        View Email Content{" "}
                    </button>

                    {/* defence pack */}
                    {(OrderDetails?.process_status == "Completed" || OrderDetails?.process_status == "Customer Called") && (
                        <>
                            <button
                                type="button"
                                className="blue-btn"
                                data-toggle="modal"
                                data-target="#defencePackModal"
                                onClick={() => setShowDefence(true)}
                            >
                                Defence Pack 1{" "}
                                <span>
                                    <img src={rightArrow} alt="rightArrow" />
                                </span>
                            </button>
                            <button
                                type="button"
                                className="blue-btn"
                                data-toggle="modal"
                                data-target="#defencePackModal1"
                                onClick={() => setShowDefencePack(true)}
                            >
                                Defence Pack 2{" "}
                                <span>
                                    <img src={rightArrow} alt="rightArrow" />
                                </span>
                            </button>

                            <button
                                type="button"
                                className="blue-btn"
                                data-toggle="modal"
                                data-target="#defencePackModal"
                                onClick={() => setShowTransaction(true)}
                            >
                                Enter Transaction Details {" "}
                            </button>
                        </>
                    )}

                    <MyModal
                        show={show}
                        close={setShow}
                        size={"lg"}
                        modalHeadTitle={"Email Content"}
                        showFooter={true}
                        primaryButtonName={"Close"}
                        secondaryButtonName={"Send Whatsapp Message"}
                        OrderDetails={OrderDetails}
                    >
                        <EmailContent />
                    </MyModal>

                    <MyModal
                        show={showDefence}
                        close={setShowDefence}
                        size={"lg"}
                        modalHeadTitle={"Defence Pack Content"}
                        showFooter={false}
                        OrderDetails={OrderDetails}
                    >
                        <DefenceContent defencePackName={null} />
                    </MyModal>
                    <MyModal
                        show={showDefencePack}
                        close={setShowDefencePack}
                        size={"lg"}
                        modalHeadTitle={"Defence Pack Content"}
                        showFooter={false}
                        OrderDetails={OrderDetails}
                    >
                        <DefenceContent defencePackName={"defencePack1"} />
                    </MyModal>

                    <MyModal
                        show={showTransaction}
                        close={setShowTransaction}
                        size={"s"}
                        modalHeadTitle={"Enter Defence Pack Details:"}
                        showFooter={false}
                        OrderDetails={OrderDetails?.length > 0 ? OrderDetails[0] : ""}
                    >
                        <TransactionModal setShowTransaction={setShowTransaction} />
                    </MyModal>

                </StyledBottomBlock>
            </div>
        </>
    );
};

export default ViewOrders;
